import React from 'react';
import styled from 'styled-components'

const FooterMotorClub = () => {
    return (
        <Wrapper>
            <p className="title">
            © Copyright 2021. All Rights Reserved. Powered by Hello Zindagi Motor Club
            </p>            
        </Wrapper>
    )
}

export default FooterMotorClub

const Wrapper = styled.div`
    background-color: #EB2027;
    margin-bottom:-18px;
    padding:0;
    .title{
        color:white;
        text-align: center;
        padding-bottom:20px;
    }
    @media only screen and (max-width: 600px) {
        display:none
    }
`