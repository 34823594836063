import React,{useEffect} from 'react';
import styled from 'styled-components';

import { useFormik } from 'formik';
import { useSelector,useDispatch } from 'react-redux';
import HeaderMotorClub from '../components/HeaderMotorClub';
import { updateCustDetails } from './actions/customerAction';
import FooterSmall from '../components/FooterSmall';
import FooterMotorClub from './FooterMotorClub';
import moment from 'moment';
import { useHistory} from 'react-router-dom';


const MotorClubCustDetails = () => {
    const dispatch = useDispatch();
    const history= useHistory();
    
const cust = useSelector(state=>state.offerReducer.customer.membershipdetails);
const storedCust = JSON.parse(sessionStorage.getItem('cust'));


const formik = useFormik({
    initialValues:{
        addVeh2:'',
        addVeh3:''
    },
    validate: values=>{
        let errors={};

//-------------vehichel2 validation--------------------------------------------
        if(!cust.vehicle2&&!values.addVeh2){
            errors.addVeh2='Please enter vehicle number to add'
        }else if(!cust.vehicle2&&!/^[A-Za-z]{2}[0-9]{2}[A-Za-z0-9]{1,7}$/.test(values.addVeh2)){
            errors.addVeh2="Please add a valid Vehicle number"
        }else if(cust.vehicle1&&cust.vehicle1.toLowerCase()===values.addVeh2.toLowerCase()){
            errors.addVeh2="Duplicate vehicle number not allowed"
        }
//------------vehicle 3 validation-----------------------------------------------
        if(!cust.vehicle3&&cust.vehicle2&&!values.addVeh3){
            errors.addVeh3='Please enter vehicle number to add'
        }else if(!cust.vehicle3&&cust.vehicle2&&!/^[A-Za-z]{2}[0-9]{2}[A-Za-z0-9]{1,7}$/.test(values.addVeh3)){
            errors.addVeh3="Please add a valid Vehicle number"
        } else if(cust.vehicle2&&(cust.vehicle2.toLowerCase()===values.addVeh3.toLowerCase()||values.addVeh3.toLowerCase()===cust.vehicle1.toLowerCase())){
            errors.addVeh3="Duplicate vehicle number not allowed"
        }

        return errors;
    }
})


const handleSubmit=(e)=>{
    if(cust.vehicle2)
    e.preventDefault();
    dispatch(updateCustDetails(
        cust.membershipid,
        cust.custmobileno,
        !cust.vehicle2?formik.values.addVeh2:'',
        formik.values.addVeh3
        ))
}

const checkLogin=()=>{
    if(!storedCust){
        history.push('/motor-club')
    }
}

useEffect(()=>{
    // checkLogin();
},[])

   
    return (
        <>
        <HeaderMotorClub/>
    
        <Wrapper bg={(require('../assets/images/mcPageImg/profileBg.jpg').default)} >
            {/* <div > */}
                {
                    cust&&<>
                            <div className="detail custName">
                                {cust.custfirstname} {cust.custlastname}
                               
                            </div>
                            <div className="detail custNum">
                                {cust.custmobileno}
                               
                            </div>
                            <div className="detail custMemId">
                                Member ID: {cust.membershipid}
                              
                            </div>
                            <div className="detail activeFrom">
                                Member Active From: { moment(cust.memvalidfrom).format("MMM Do YYYY")}
                            </div>
                            <div className="detail custStatus">
                                Status: {cust.status===1&&"Active"}
                                {cust.status===0&&"InActive"}
                               
                            </div>


                            <div className="existVehCont" >
                                {
                                  cust.vehicle1&&  
                                <div className="existVeh1 existVeh detail">
                                  <span className="staticVeh">Vehicle 1</span>: {cust.vehicle1}
                                </div>
                                }
                                {
                                    cust.vehicle2&&
                                <div className="existVeh1 existVeh detail">
                                    <span className="staticVeh">Vehicle 2</span>: {cust.vehicle2}
                                </div>
                                }
                                {
                                    cust.vehicle3&&
                                <div className="existVeh1 existVeh detail">
                                   <span className="staticVeh">Vehicle 3</span>: {cust.vehicle3}
                                </div>

                                }
                                
                            </div>

                                  {
                                      !cust.vehicle2&&(
                            <div className="addVeh">
                                <input type="text"
                                 name='addVeh2' 
                                 className="detail addVeh2 " 
                                 placeholder="Vehicle 2"
                                 maxLength='11'
                                 {...formik.getFieldProps('addVeh2')}
                                  />
                                <div className="addVehIcon" >
                                    {/* <img src={require('../assets/images/mcPageImg/car-gray-mini.png').default} alt="" /> */}
                                </div>
                            </div>
                                      )
                                    }   

                                    {formik.values.addVeh2&&formik.values.addVeh2.length>=5&&
                                            <div className="field-err-text">{formik.errors.addVeh2}</div>
                                            }
                           

                                    {
                                       !cust.vehicle3&&cust.vehicle2&&
                                        <div className="addVeh">
                                            <input type="text"
                                            name='addVeh3' 
                                            className="detail addVeh2" 
                                            placeholder="Vehicle 3"
                                            maxLength='11'
                                            {...formik.getFieldProps('addVeh3')}
                                            />
                                            <div className="addVehIcon" >
                                                {/* <img src={require('../assets/images/mcPageImg/car-gray-mini.png').default} alt="" /> */}
                                            </div>
                                        </div>
                                    }

                                  {formik.values.addVeh3&&formik.values.addVeh3.length>=5&&
                                    <div className="field-err-text">{formik.errors.addVeh3}</div>
                                    }

                                <div className="detail addBtnContainer">
                                    {!cust.vehicle3&&(
                                        formik.dirty&&formik.isValid?
                                        <button className="addBtn" onClick={handleSubmit} >+ Add vehicle</button>
                                        :
                                        <button className="addBtn disabled-btn" disabled onClick={handleSubmit} >+ Add vehicle</button>
                                    )
                                    
                                    }

                                </div>


                                                                      
                                    {/* {
                                        formik.dirty&&formik.isValid?
                                            <button className="addBtn" onClick={handleSubmit} >+ Add vehicle</button>
                                            :
                                            <button className="addBtn disabled-btn" disabled onClick={handleSubmit} >+ Add vehicle</button>
                                    } */}
                    </>
                }

            {/* </div> */}
            
        </Wrapper>
        <FooterSmall/>
        <FooterMotorClub/>
        </>
    )
}

export default MotorClubCustDetails

const Wrapper = styled.div`
display:flex;
/* width:100vw; */

flex-direction: column;
align-items: center;
min-height: 85vh;
padding-top:2%;
/* padding-bottom: 115px; */
position:relative;
margin-top: 3%;

:before{
    content: '';
    background: ${props=> `url(${props.bg})`};
    position: absolute;
    /* top: 100px; */
    left: 0;
    right: 0;
    bottom:0;
    display: inline-block;
    background-position: bottom;
    height: 100%;
    background-repeat: no-repeat;
    z-index: -1;
    background-size: contain;
    @media only screen and (max-width: 600px) {
        
        /* background-size: contain;
        opacity: 100%;
        bottom: 0;
        height:auto; */
        }
}
    
.detail{
    color:#EB2027;
    border: 1px solid #0000006e;
    border-radius: 14px;
    /* width: 313px; */
    padding: 5px 13px;
    margin-top: 11px;
    font-size: 20px;
    width:430px;
}
.custName{
    font-weight: 600;
    text-transform: capitalize;
    font-size: 20px;
}
.addVeh{
    margin-top: 20px;
    display:flex;
    align-items: center;
    gap:0 5px;
    input{
        text-transform: uppercase;
    }
    .addVeh2{
        color:black;
    }
    .addVehIcon{
        margin-top: 7px;
        img{
            height: 100%;
            padding: 8px 2px;
            background-color: white;
            border-radius: 10px;
            width: 55px;
        }
    }
}
.existVehCont{
    margin-top: 40px;
    .existVeh{
        border:1px solid lightgray;
        background-color: white;
        color:black;
        text-transform: uppercase;
    }
    .staticVeh{
        text-transform: capitalize;
    }
}
.addBtnContainer{
    border:none;
}
.addBtn{
    border:none;
    border-radius: 11px;
    background-color: #eb2027;
    color:white;
    padding:3px 13px;
    margin-top: 8px;
}
.disabled-btn{
    background-color:#df102cc7 ;
}
@media only screen and (max-width: 600px) {
        min-height: 80vh;
        /* padding-bottom: 115px; */
        .detail{
            width: 203px;
            font-size: 10px;
            height: 25px;
            padding:4px 10px;
            margin: 5px auto;
            border-radius: 8px;
        }
        /* .existVehCont{
            width:249px;
            .existVeh{
                width:100%;
            }
        } */
        /* .addVeh{
            input{
                width:249px;
            }
        } */
        .addBtn{
            font-size: 10px;
            padding: 2px 9px;
            margin-top: 0px;
        }
        }
        
`