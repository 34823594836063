import React,{useState} from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import styled from 'styled-components';
import samplePDF from './tncApp.pdf';


const options = {
    cMapUrl: 'cmaps/',
    cMapPacked: true,
  };

const TermsAndCondition = () => {

    const [file, setFile] = useState('./tncApp.pdf');
 

    const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }
    return (
        <Wrapper>
                <Document
            file={file}
            onLoadSuccess={onDocumentLoadSuccess}
            options={options}
          >
            {
              Array.from(
                new Array(numPages),
                (el, index) => (
                  <Page
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                  />
                ),
              )
            }
          </Document>
        </Wrapper>
    )
}

export default TermsAndCondition

const Wrapper = styled.div`

`