import React from 'react';

import styled from 'styled-components';

const Loader = ({width}) => {
  return (
      <Wrapper width={width} >
        <img src={require('../assets/images/mcPageImg/moving-scooter-loader.svg').default} alt="" />       
        {/* <img src={require('../assets/images/mcPageImg/loader-app.svg').default} alt="" />        */}
      </Wrapper>
    
  );
};

export default Loader;

const Wrapper = styled.div`
height:42px;
width: ${props=>props.width||'auto'};
  img{
    height: 100%;
    
  }
`