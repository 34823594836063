import React, { useState, useEffect } from 'react';
import {
    Link,
    useLocation,useParams
  } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { getCust, } from './actions/customerAction';
import {checkLeadInterest,captureLead} from './api/index';
import {useFormik} from 'formik';
import jwt from 'jsonwebtoken';
import {isMobile} from "react-device-detect";

import { withStyles } from '@material-ui/core/styles';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import {InputAdornment,TextField} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import {FormGroup,LinearProgress} from '@material-ui/core/';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import OtpInput from 'react-otp-input';
import Timer from '../components/Timer';
import logo2 from '../assets/images/logo2.png';
import {sendOTP2,validateOTP2} from './config/smsGateway';
import styled from 'styled-components';


// import jsonData from '../config.json';
// const API_BASE_URL = `https://fgngapi.futuregroup.in/api/v1`;


const prodObj = {
  'cyber-protect': {
    title: 'Cyber Protect',
    img: 'hacker.svg',
    prodId: 10
  },
  'laptop-insurance': {
    title: 'Laptop Insurance',
    img: 'laptop-insu.svg',
    prodId: 3
  },
  'tv-insurance': {
    title: 'TV Insurance',
    img: 'entertainment.png',
    prodId: 2
  },
  'bike-insurance': {
    title: 'Bike Insurance',
    img: 'bike.svg',
    prodId: 5
  },
  'car-insurance': {
    title: 'Car Insurance',
    img: 'car_insu.svg',
    prodId: 6
  },
  'personal-accident-insurance': {
    title: 'Personal Accident Insurance',
    img: 'accident.svg',
    prodId: 8
  },
  'health-insurance': {
    title: 'Health Insurance',
    img: 'health.svg',
    prodId: 9
  },
  'motor-club': {
    title: 'The Motor Club',
    img: 'motor-club-text.svg',
    prodId: 7
  },
  'super-benefits': {
    title: 'Super Benefits',
    img: 'unlock-icon.svg',
    prodId: 11
  },
};


function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const RedCheckbox = withStyles({
    root: {
      color: '#f239398c',
      '&$checked': {
        color: '#F23939',
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

const RegistrationNew = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const custStoredData = JSON.parse(sessionStorage.getItem('cust'));


  const formik = useFormik({
    initialValues:{
      firstName:'',
      lastName:'',
      terms:false,
      dob:'',
      mobNumber:''
    },
    validate:values=>{
      let errors={};

      if(!custStoredData&&activeCard!==3&&!values.mobNumber){
        errors.mobNumber="Please enter a mobile number";
      } else if(activeCard!==3&&!/^[6-9][0-9]{9}$/i.test(values.mobNumber)){
        errors.mobNumber= "Please enter valid number"
      } else if(activeCard!==3&&/^(.)\1{9}$/.test(values.mobNumber)){
        errors.mobNumber= "Please enter valid number"
      }
      //--------------------------------------------------------------
      if(!custStoredData&&!values.firstName){
        errors.firstName="Please Enter Your First Name"
      }else if(!custStoredData&&!/^[a-zA-Z ]{3,30}$/.test(values.firstName)){
        errors.firstName="Please Enter a Valid First Name"
      }

      if(!custStoredData&&!values.lastName){
        errors.lastName="Please Enter Your First Name"
      }else if(!custStoredData&&!/^[a-zA-Z ]{3,30}$/.test(values.lastName)){
        errors.lastName="Please Enter a Valid First Name"
      }
     
      //----------------DOB validate--------------

      if(values.dob===""){
        errors.dob="Please Select Date Of Birth"
      }
        const date = new Date();
        let year = date.getFullYear()-18;
        let month = date.getMonth()+1;
        let day = date.getDate();
//----------------------------------------
        let dobVal = values.dob.split('-');
        let selectedDate = new Date(dobVal[0],dobVal[1],dobVal[2]).getTime();
        let minDate = new Date(year,month,day).getTime();
        if(selectedDate>minDate){
          errors.dob = "Age should be 18+ years"
        }
      //--------------Terms Validate-------------------
      if(!values.terms){
        errors.terms="Please Agree to Terms and Conditions to Proceed"
      }

      return errors
    }
  });

  

  let query = useQuery();
  let product = query.get("product");
  let prodId = prodObj[product].prodId;


 
  const [activeCard, setActiveCard] = useState(0);
  const [otpValue, setOtpValue] = useState('');
  const [custDetail, setCustDetail] = useState(null);
  const [timeValue, setTimeValue] = useState(30);
  const [smsUniqueCode, setSmsUniqueCode] = useState(0);
  const [loading, setLoading] = useState(false);
  const [maxDob, setMaxDob] = useState('');

  const onChangeMobile = (e) => {
    formik.setFieldValue('mobNumber',e.target.value.replace(/[a-zA-Z$&/+,:;=?@#|'<>.^*()%!_-]/gi,""));
        
  }

  
  const verifyBtn= async(e)=>{
    e.preventDefault();
    setOtpValue('');
    formik.setFieldError("otp","");
  
    try {
      setLoading(true);
      const {data} = await checkLeadInterest(formik.values.mobNumber,prodId);
      setLoading(false);
     setCustDetail(data);     
      const otp = await sendOTP2(formik.values.mobNumber);
      setSmsUniqueCode(otp.data.responseObject.unique_code);
      setActiveCard(2);
    } catch (error) {
        const {data} = await sendOTP2(formik.values.mobNumber);
        setLoading(false);
        setSmsUniqueCode(data.responseObject.unique_code);
        setActiveCard(2);
        console.log("check lead catch called");
      
    }
}

const verifyOTP= async(e)=>{
  e.preventDefault();
  if(!otpValue||otpValue.length<4){
    formik.setFieldError("otp","Please enter a valid OTP");
    return
  }
  
  try{
    setLoading(true);
    await validateOTP2(formik.values.mobNumber,smsUniqueCode,otpValue);
    setLoading(false);
    if(custDetail){
      setActiveCard(10);
    }else{
      setActiveCard(3);
    }
  } catch(error){
    setLoading(false);
    formik.setFieldError("otp","Invalid OTP");
  }
}

const register=async(e)=>{
  e.preventDefault();
   
  try {
    setLoading(true);
     await captureLead(
      custStoredData&&custStoredData.mobileNumber?custStoredData.mobileNumber:formik.values.mobNumber,
      custStoredData&&custStoredData.firstName?custStoredData.firstName:formik.values.firstName,
      custStoredData&&custStoredData.lastName?custStoredData.lastName:formik.values.lastName,
            formik.values.dob,
            prodId
            )
      setLoading(false);

    setActiveCard(4);
  } catch (error) {
    setLoading(false);
    setActiveCard(11); 
  }
}

const alreadyLogin=async()=>{
  let date = new Date();
  let month = date.getMonth();
  if(month<10){
    month='0'+month
  }
  let dt = date.getDate();
  if(dt<10)
  {
    dt='0'+dt
  }
  let year = date.getFullYear()-18;
  setMaxDob(`${year}-${month}-${dt}`);
 
  if(custStoredData){
    try {
      setLoading(true);
      await checkLeadInterest(custStoredData.mobileNumber,prodId);
      setLoading(false);
      
      setActiveCard(10);
      
    } catch (error) {
      setLoading(false);
      setActiveCard(3);
    }
  }else if(params.number){
    const decoded = jwt.verify(params.number,'secret');
    setActiveCard(3);
    dispatch(getCust(decoded.userId,"auth"));
  }else{
    setActiveCard(1);
  }

}

 
  useEffect(() => {
    alreadyLogin();
   
  }, [])


    return (
        <Grid container className="reg-main-con">
          {/* <Formik> */}
        

            <Register >

            <Grid item md={6} className={`reg-left-con}`}>
            <div className="reg-title">
                <Link to={params.number?`/${params.number}`:'/'}>
                    <img alt="" src={logo2} />
                </Link>
            </div>
            <div className="title-block">
                {
                product === 'super-benefits' &&
                <div className="unlock-text1">Unlock the</div>
                }
                <div className={`reg-img-con ${product === 'super-benefits' ? 'reg-img-unlock' : null}`}>
                    <img alt="" src={require(`../assets/images/${prodObj[product].img}`).default} />
                </div>
               
            </div>
            </Grid>

          <Grid item md={6} xs={12} className={`reg-right-con ${activeCard === 3 ? 'right-con-card3' : null}`}>
            <div>

            {
              loading&&activeCard!==1&&activeCard!==2&&activeCard!==3&&
              <LinearProgress color='secondary' style={{margin:'auto',marginTop:'8px',width:'200px'}} />
            }
              {
                activeCard === 1 &&
                <div>
                  <div className="reg-helper-text">Enter mobile number and login</div>
                  <div className="reg-input-con mob-input">
                    <input type="text" placeholder="" maxLength="10" name="mobNumber" 
                    onChange={onChangeMobile}
                    onBlur={formik.handleBlur}
                    value={formik.values.mobNumber}                     
                    />
                <div className="country-code">+91</div>
                <button 
                className={formik.errors.mobNumber||!formik.dirty?'reg-red-btn-disabled':"reg-red-btn"} 
                onClick={verifyBtn}
                style={{border:'none'}}
                disabled={formik.errors.mobNumber||!formik.dirty?true:false}
                >Verify</button>
                {
                  loading&&activeCard===1&&
                <LinearProgress color='secondary' style={{marginLeft:'10px',marginTop:'10px',width:'200px'}} />
                }
                {formik.values.mobNumber&&(formik.values.mobNumber.length>=10||!/^[6-9]/i.test(formik.values.mobNumber))&&
                <div className="field-err-text">{formik.errors.mobNumber}</div>
                }
              </div>
            </div>
          }
          

          {
            activeCard === 2 &&
            <>
              <div className="reg-helper-text">We sent OTP code to verify your number</div>
              <div className="reg-input-con otp-input-con">
                <OtpInput
                  value={otpValue}
                  onChange={(val)=>setOtpValue(val)}
                  numInputs={4}
                  isInputNum={true}
                  separator={<span></span>}
                  containerStyle={'otp-container'}
                  
                />
                <div className={otpValue.length<4?'reg-red-btn-disabled':'reg-red-btn'}
                onClick={verifyOTP}
                  >Next</div>
              </div>

              <div className={`resend-otp-btn ${timeValue === 0 ? null : 'disable-btn'}`}
              onClick={verifyBtn}
               >Resend OTP</div>
               {
                 loading&&activeCard===2&&
                 <LinearProgress color='secondary' style={{marginLeft:'5px',marginTop:'10px',width:'200px'}} />
               }
              <div className="field-err-text">{formik.errors.otp}</div>
              <div className="timer-con"><Timer initialSeconds={30} timeValue={timeValue} setTimeValue={setTimeValue} /></div>
            </>
          }
        </div>
        

        {
          activeCard === 3 &&
                   
            <div className="card-3">
              <div className="info-heading">INFORMATION</div>
              <div className="pingBg">
                <Grid container className="mcCSS">
                  {!custStoredData&&
                  <>
                  <Grid item md={6} className="mcCSS">
                    <div className="reg-helper-text-2" style={{width:'100%',float:'inherit'}} >First Name</div>
                    <input type="text" 
                      name="firstName"
                      {...formik.getFieldProps('firstName')}
                      />
                      <br />
                      {formik.values.firstName&&(formik.values.firstName.length>=3)&&
                      <div className="field-err-text">{formik.errors.firstName}</div>
                      }
                  </Grid>
                  <Grid item md={6} className="mcCSS">
                    <div className="reg-helper-text-2" style={{width:'100%',float:'inherit'}}>Last Name</div> 
                    <input type="text" name="lastName" {...formik.getFieldProps('lastName')} />
                    <br />
                    {formik.values.lastName&&formik.values.lastName.length>=3&&
                    <div className="field-err-text">{formik.errors.lastName}</div>
                    }
                  </Grid>
                  </> 
                  }
                  
                </Grid>
                <Grid container >
                  <Grid item md={12} xs={12} >
                        <div className="dobContainer" >
                          <div className="reg-helper-text-2 dobHeading">Date of Birth</div>
                          {
                            !isMobile&&
                          <input type="date" required={true} name="dob" {...formik.getFieldProps('dob')} max={maxDob} />
                          }

                          {isMobile&&
                          <TextField
                           id="date"
                            type="date"
                            required={true} name="dob" {...formik.getFieldProps('dob')}
                            InputProps={{inputProps: { max: maxDob},
                            endAdornment: (
                              <InputAdornment position="end">
                                <CalendarTodayIcon />
                              </InputAdornment>
                            )
                           }}
                            style={{width:'200px'}}                   
                          />
                          }
          
                          {formik.touched.dob&&
                          <div className="field-err-text">{formik.errors.dob}</div>
                          }
                        </div>
                  </Grid>
                </Grid>

                                
                <div className="terms-con">
                  <FormGroup row>
                    <FormControlLabel
                      control={<RedCheckbox checked={formik.values.terms} onBlur={formik.handleBlur} onChange={formik.handleChange} name="terms" />}
                      label={
                        <div className="agree-terms">I agree to the {` `}
                          {product === 'motor-club' ? <span><Link to="/tnc" target="_blank" rel="noreferrer">Terms & Conditions</Link> and {` `}</span> : null}
                          <Link to="/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</Link>.
                          {
                            formik.touched.terms&&
                          <div className="field-err-text">{formik.errors.terms}</div>
                          }
                        </div>
                      }
                    />
                  </FormGroup>{
                    loading&&activeCard===3&&
                    <LinearProgress color='secondary' style={{margin:'auto',marginTop:'10px',width:'200px'}} />
                  }
                </div>
                {formik.dirty&&formik.isValid&&formik.values.terms?
                <button className={activeCard !== 3 ? "reg-red-btn info-submit" :"myBtnCss"}
                onClick={register}
                style={{border:'none'}}
                >Submit</button>:
                <button className={activeCard !== 3 ? "reg-red-btn info-submit" :"myBtnCss-disabled"}
                style={{border:'none'}}
                
                onClick={()=>formik.setFieldTouched('terms',true)}
                >Submit</button>
                }
            
              </div>
            </div> 
        }
        

        {
          activeCard === 4 &&
          <>
            <div className="card-10">
              <div>Thank you for registering with Hello Zindagi.</div>
              <div>We will connect with you shortly and assist you with the further buying journey.</div>
              {/* Thank you for registering with Hello Zindagi. We will connect with you shortly and assist you with the further buying journey. */}

              {/* <Link to="/offers">
                <button type="button" className="btn mt-5 availBtn ">
                  Avail Benefits
                </button>
              </Link> */}
            </div>
          </>
        }

        {
          activeCard === 5 &&
          <>
          
            <div className="card-10">
              <div>Thank You!</div>
              <div>You will get a call back from our ‘Certified Insurance Assistant’ to help you buy the product</div>
              <div>You can also reach us directly on  <a href="https://wa.me/918767516040">Whats App +91-8767516040</a>  or email at <a href="mailto:care@hellozindagi.co">care@hellozindagi.co</a></div>
            </div>
          </>
        }
        {
          activeCard === 11 &&
          <>
          
            <div className="card-10">
              <div>Something went wrong!</div>
              <div>Please try again after some time</div>
              <div>You can also reach us directly on  <a href="https://wa.me/918767516040">Whats App +91-8767516040</a>  or email at <a href="mailto:care@hellozindagi.co">care@hellozindagi.co</a></div>
            </div>
          </>
        }

        {
          activeCard === 10 &&
          <>
            <div className="card-10 card-15">
              <div>You are our Existing Member.</div>
              <div className="already-text">Please contact our Customer Care for further details.</div>
            </div>
          </>
        }

      
        </Grid>
        </Register>
      {/* </Formik> */}
    </Grid>
    )
}

export default RegistrationNew

const Register = styled.div`
  display:flex;
  width:100%;
  .myBtnCss,.myBtnCss-disabled{
    padding:5px 20px;
    width:auto;
  }
  .reg-right-con{
    background-color: rgb(241 241 241);
    align-items: center;
  }
  .dobContainer{
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    input{
      max-width:180px;
      margin-top: 6px
    }
  }
  .info-heading{
    margin-bottom: 60px;
    text-align: center;
  }
  .MuiInputAdornment-positionEnd{
    margin-left: 0;
  }
  @media only screen and (max-width: 600px) {
    .reg-helper-text-2{
      margin-top: 15px;
    }
    .dobContainer{
      display: block;
      
      .dobHeading{
        width:100%;
      }
      input{
        float:inherit;
        width:80%;
        margin-top: 15px;
      }
    }
  }

`
