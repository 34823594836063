import axios from 'axios';

import { toast } from 'react-toastify';

import {
 
  FETCH_CUSTOMER_DETAILS,
  REGISTER_MC,
  UPDATE_MC_DETAIL
} from '../constants';

const API_BASE_URL = `https://fgngapi.futuregroup.in`;


// const GET_MEM_DETAIL = "https://fgngapi.futuregroup.in/preprod-motorclub/api/v1/getCustMembershipData"
// const API_REGISTER_MC = "https://fgngapi.futuregroup.in/preprod-motorclub/api/v1/FGnGCustRegisteration";
// const API_UPDATE_CUST= "https://fgngapi.futuregroup.in/preprod-motorclub/api/v1/updateCustMembershipData";



const API = axios.create({baseURL:API_BASE_URL});

API.interceptors.request.use((req)=>{
 
  req.headers['Authorization'] = 'Basic ZmduZzpGZ25nQDEyMzRwcm9k'
  
return req;
});

export const getCust = (custMobileNo,auth)=>async(dispatch)=>{

  try {
    const {data} = await API.post(`/motorclub/api/v2/getCustMembershipData`,{
      sellerid:203,custMobileNo,membershipType:1,authenticated:1
    })
    dispatch({type:FETCH_CUSTOMER_DETAILS, payload:data})
  } catch (error) {
    console.log(error.message)
  }
}

export const registerCust = (setLoading,history,custMobileNo,
          firstName,
          lastName,
          vehicleNum,
          vehicleNum2,
          vehicleNum3,
          )=>async(dispatch)=>{

            let vStr=vehicleNum;
            if(vehicleNum&&vehicleNum2){
              vStr=`${vehicleNum},${vehicleNum2}`
            }
            if(vehicleNum&&vehicleNum2&&vehicleNum3){
              vStr=`${vehicleNum},${vehicleNum2},${vehicleNum3}`
            }
     
  try {
    setLoading(true);
    const {data} = await API.post(`/api/v1/FGnGCustRegisteration`,{
      sellerId:203,
      custMobileNo,
      custFirstName:firstName,
      custLastName:lastName,
      vehRegNumber:vStr,
      membershipType:1,
      termVersion:"1.0",
      policyVersion:"1.0",
      consentSigned:1,
      chanelid:2,
      utmSource: JSON.parse(localStorage.getItem('utmSource')),
          utmMedium: JSON.parse(localStorage.getItem('utmMedium')),
          utmCampaign: JSON.parse(localStorage.getItem('utmCampaign')),
          utmTerm: JSON.parse(localStorage.getItem('utmTerm')),
          utmContent: JSON.parse(localStorage.getItem('utmContent'))
    })

    setLoading(false);    
    dispatch({type:REGISTER_MC, payload:data});
    console.log("session saved in action");
    history.push('/motor-club/offers');
    
  } catch (error) {
    console.log(error.response,error.message);
    setLoading(false);
    toast.error("Something went wrong, please try again later",{
      position: "top-right",
      autoClose: 1500,
      progress: undefined,
    })
    
  }
}

export const updateCustDetails=(membershipId,custMobileNum,vehRegNum2,vehRegNum3)=>async(dispatch)=>{

  try {

    const {data} = await API.post(`/api/v1/updateCustMembershipData`,{
      sellerid:203,
      membershipid:membershipId,
      custmobileno:custMobileNum,
      membershipType:1,
      vehregNumber2:vehRegNum2,
      vehregNumber3:vehRegNum3
    }) 
    dispatch({type:UPDATE_MC_DETAIL, payload:data});
  } catch (error) {
    console.log('error updating cust details');
    
  }

}


export const registerCustHZ=(history,custMobileNo,
  firstName,
  lastName,
  dob,prodId)=>async(dispatch)=>{

  try {
    await API.post(`${API_BASE_URL}/api/v1/hellozindagi/registerLead`,{
        sellerId:203,
        custMobileNo,
        custFirstName:firstName,
        custLastName:lastName,
        custDOB:dob,
        platformprodid:prodId,
        leadsource: 2
    })
    
  } catch (error) {
    console.log('error registering cust for HZ');
  }
}