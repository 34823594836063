import React,{useState} from 'react';

import {useFormik} from 'formik';
import { ToastContainer} from 'react-toastify';
import OtpInput from 'react-otp-input';
import styled from 'styled-components';

import {useHistory} from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { FETCH_CUSTOMER_DETAILS } from './constants';
import FooterSmall from "../components/FooterSmall";
import FooterMotorClub from './FooterMotorClub';
import HeaderMotorClub from "../components/HeaderMotorClub";
import Timer from '../components/Timer';
import {getCustDetails} from './api/index';
import { registerCust } from './actions/customerAction';
import {sendOTP2,validateOTP2} from './config/smsGateway';
import Loader from './Loader';

function MotorClub() {
  const dispatch = useDispatch();
  const history= useHistory();

  const [activeCard, setActiveCard] = useState(1);
  
  const [smsUniqueCode, setSmsUniqueCode] = useState(0);
  const [custDetail, setCustDetail] = useState(null);
  const [timeValue, setTimeValue] = useState(30);
  const [totalVehicle, setTotalVehicle] = useState(1);
  const [loading, setLoading] = useState(false);


  const formik = useFormik({
    initialValues:{
      firstName:'',
      lastName:'',
      vehicleNum:'',
      vehicleNum2:'',
      vehicleNum3: '',
      mobNumber:'',
      otp:''
    },
    validate:values=>{
      let errors={};

  //-------------Number validation------------------
      
      if(activeCard!==3&&!values.mobNumber){
        errors.mobNumber="Please enter mobile number";
      } else if(activeCard!==3&&!/^[6-9][0-9]{9}$/i.test(values.mobNumber)){
        errors.mobNumber= "Please enter a valid mobile number"
      } else if(activeCard!==3&&/^(.)\1{9}$/.test(values.mobNumber)){
        errors.mobNumber= "No repeated mobile number allowed"
      }
      //--------------------------------------------------------------

      //-------------------otp validation----------------------
      if(!values.otp){
        errors.otp="Please enter OTP to proceed"
      }else if(values.otp&&values.otp.length<4){
        errors.otp="Please enter 4 digit OTP"
      }

      if(!values.firstName){
        errors.firstName="Please Enter Your First Name"
      }else if(!/^[a-zA-Z ]{3,30}$/.test(values.firstName)){
        errors.firstName="Please Enter a Valid First Name"
      }

      if(!values.lastName){
        errors.lastName="Please Enter Your First Name"
      }else if(!/^[a-zA-Z ]{3,30}$/.test(values.lastName)){
        errors.lastName="Please Enter a Valid First Name"
      }

      //---------------Vehicle Validate------------------
      if(!values.vehicleNum){
        // errors.vehicleNum= "Please Enter Vehicle Number"
      } else if(values.vehicleNum&&!/^[A-Za-z]{2}[0-9]{2}[A-Za-z0-9]{1,7}$/.test(values.vehicleNum)){
        errors.vehicleNum= "Please Enter a Valid Vehicle Number"
      }
      //-------Vehicle2------------
      if(totalVehicle>=2&&!values.vehicleNum2){
        errors.vehicleNum2= "Please Enter Vehicle Number"
      } else if(totalVehicle>=2&&values.vehicleNum2&&!/^[A-Za-z]{2}[0-9]{2}[A-Za-z0-9]{1,7}$/.test(values.vehicleNum2)){
        errors.vehicleNum2= "Please Enter a Valid Vehicle Number"
      } else if(totalVehicle>=2&&values.vehicleNum&&values.vehicleNum2===values.vehicleNum){
        errors.vehicleNum2= "Duplicate Vehicle Number Not Allowed";
        
      }
      //--------vehicle 3------------------
      if(totalVehicle===3&&!values.vehicleNum3){
        errors.vehicleNum3= "Please Enter Vehicle Number"
      } else if(totalVehicle===3&&values.vehicleNum2&&!/^[A-Za-z]{2}[0-9]{2}[A-Za-z0-9]{1,7}$/.test(values.vehicleNum3)){
        errors.vehicleNum3= "Please Enter a Valid Vehicle Number"
      } else if(totalVehicle===3&&values.vehicleNum2&&values.vehicleNum3===values.vehicleNum2){
        errors.vehicleNum3= "Duplicate Vehicle Number Not Allowed"
          
      }else if(totalVehicle===3&&values.vehicleNum&&values.vehicleNum3===values.vehicleNum){
        errors.vehicleNum3= "Duplicate Vehicle Number Not Allowed"
          
      }
     
      return errors
    }
  });


 
  const verifyBtn=async(e)=>{
    e.preventDefault();
    formik.setFieldTouched('otp',false);
    formik.setFieldValue('otp','');
    try {
      setLoading(true);
      const {data} = await getCustDetails(formik.values.mobNumber);
      const otp = await sendOTP2(formik.values.mobNumber);
      setLoading(false);
      setCustDetail(data);
      setSmsUniqueCode(otp.data.responseObject.unique_code);
      setActiveCard(2);
    } catch (error) {
      
      try {
        const {data} = await sendOTP2(formik.values.mobNumber);
        setLoading(false);
        setSmsUniqueCode(data.responseObject.unique_code);
        setActiveCard(2)
      } catch (error) {
        formik.setFieldError("mobNumber","Something went wrong, please try again later");
        setLoading(false);
        
      }
    }
  }

  const verifyOTP=async(e)=>{
    e.preventDefault();
    formik.setFieldTouched('otp',true);
    if(!formik.values.otp||formik.errors.otp) return
    try {
      setLoading(true);
      await validateOTP2(formik.values.mobNumber,smsUniqueCode,formik.values.otp);
        setLoading(false);
      if(!custDetail){
        setActiveCard(3)
      }
      if(custDetail){
        dispatch({type:FETCH_CUSTOMER_DETAILS, payload:custDetail});
        history.push('/motor-club/offers');
      }
    
      
    } catch (error) {
      setLoading(false);
      formik.setFieldError("otp","Invalid OTP");
    }
  }

  const register=(e)=>{
    e.preventDefault();
    dispatch(registerCust(
        setLoading,
        history,
        formik.values.mobNumber,  
        formik.values.firstName,
        formik.values.lastName,
        formik.values.vehicleNum,
        formik.values.vehicleNum2,
        formik.values.vehicleNum3,
        ))
  }

  const handleNumberChange=(e)=>{
    formik.setFieldValue('mobNumber',e.target.value.replace(/[a-zA-Z$&/+,:;=?@#|'<>.^*()%!_-]/gi,""));
  }

  return (
    <>
        
      <HeaderMotorClub />
      <ToastContainer limit={1} />
      <Wrapper>
    <div className="motorClubPage">
      <div className="content">
      {
        activeCard!==3&&activeCard!==4&&
        <div className="mobRespCss">
          <img className="img-fluid respWidthLeft" src={require("../assets/images/mcPageImg/standing-car.svg").default} alt="" />
        </div>
      }
      {/* {
        isMobile&&activeCard===2&&
        <div className="mobRespCss">
          <img className="img-fluid respWidthLeft" src={require("../assets/images/mcPageImg/scooty-moving.svg").default} alt="" />
        </div>
      } */}
      {
        activeCard===3&&
        <div className="mobRespCss">
          <img className="img-fluid respWidthLeft" src={require("../assets/images/mcPageImg/scooty-moving.svg").default} alt="" />
        </div>
      }
      {
        activeCard!==4&&

      
        <div className="mobRespCss2">
        {
            activeCard === 1 &&
            <div className="numberContainer">
              <div className="reg-helper-text login-text login-header" style={{opacity:1}} >Login/ Sign up</div>
              <div className="reg-input-con mob-input">
                <input type="text" className="login-input" placeholder="Mobile Number" 
                 name="mobNumber" maxLength="10"
                value={formik.values.mobNumber}
                 onChange={handleNumberChange}
                 onBlur={formik.handleBlur}
                />
               
              
                
                <div style={{marginTop:'8px',width:'200px'}} >
                  {formik.values.mobNumber&&(!/^[6-9]/i.test(formik.values.mobNumber)||formik.values.mobNumber.length>=10)&&
                  <>
                  <div className="field-err-text" style={{marginTop:'-4px',marginBottom:'10px'}} >{formik.errors.mobNumber}</div>
                </>
                }
                {
                  loading&&
                   <Loader  />
                }
                <div className="btnContainer mob-login-next">
                <button 
                className={` ${formik.errors.mobNumber||!formik.dirty?'reg-here-btn-disabled':"reg-here-btn"}`} 
                onClick={verifyBtn}
                style={{border:'none'}}
                disabled={formik.errors.mobNumber||!formik.dirty?true:false}
                >NEXT</button>

                </div>
                  </div>

              </div>
            </div>
          }

          {
            activeCard === 2 &&
            <div className="otpContainer">
              <div className="reg-helper-text login-text">Enter 4 digit code</div>
              <div className="reg-helper-text" style={{width:'202px'}}>OTP sent to you on your mobile phone {formik.values.mobNumber}</div>
              <div className="reg-input-con otp-input-con">
                <OtpInput
                  value={formik.values.otp}
                  onChange={(val)=>{formik.setFieldValue("otp",val)} }
                  className={'mc-otp-inputVal'}
                  numInputs={4}
                  isInputNum={true}
                  separator={<span></span>}
                  // containerStyle={'mc-otp-input'}
                  containerStyle={{display:'flex'}}
                  inputStyle={{width:'30px',marginRight:'12px',borderRadius:'5px',border:'1px solid #00000052'}}
                  name='otp'
                  
                />
              <div className={`resend-otp-btn ${timeValue === 0 ? null : 'disable-btn'}`}
              onClick={verifyBtn}
               >Did not received OTP? Resend OTP.</div>
               <div>
               {
                 formik.touched.otp&&
                 <div className="field-err-text">{formik.errors.otp}</div>
               }
               {
                  loading&&
                  // <LinearProgress color='secondary' style={{marginTop:'10px', width:'200px'}} />
                  <Loader width='200px' />
                }
              </div>
              <div className="timer-con" style={{marginBottom:'10px'}}  ><Timer initialSeconds={30} timeValue={timeValue} setTimeValue={setTimeValue} /></div>
              </div>
                <button className={formik.errors.otp?'reg-here-btn-disabled mob-otp-btn':'reg-here-btn mob-otp-btn'} 
                onClick={verifyOTP}
                  >VERIFY</button>

            </div>
          }


      {
          activeCard === 3 &&
          <>
              <div className="mcregContainer">
                <div className="reg-helper-text login-text">Register</div>
                <div className='mcReg-input-con' >
                  <div className="nameConainer mob-nameConainer">
                    <div className="fNameContainer" >
                        <input type='text'
                        className="login-input"
                          name='firstName'
                          {...formik.getFieldProps('firstName')}
                          placeholder="First Name"/>
                          {formik.values.firstName&&(formik.values.firstName.length>=3||formik.touched.firstName)&&
                            <div className="field-err-text">{formik.errors.firstName}</div>
                            }
                    </div>
                    <div className="lNameContainer" >
                        <input 
                        type="text"
                        name="lastName"
                        {...formik.getFieldProps('lastName')}
                        placeholder="Last Name"/>
                        {formik.values.lastName&&(formik.values.lastName.length>=3||formik.touched.lastName)&&
                          <div className="field-err-text">{formik.errors.lastName}</div>
                          }
                      </div>
                    </div>
                    <div className='vehicleCons'>
                        <div className="vehRow">
                          <input 
                          maxLength="11"
                          name="vehicleNum"
                          {...formik.getFieldProps('vehicleNum')}
                          placeholder="Vehicle Number"
                          />
                          {/* <img height='100%' src={require('../assets/images/mcPageImg/car-gray-mini.svg').default} alt="" /> */}
                        </div>
                        {formik.values.vehicleNum&&(formik.values.vehicleNum.length>=5)&&
                              <div className="field-err-text">{formik.errors.vehicleNum}</div>
                          }
                          
                        {/* {
                      formik.values.vehicleNum&&!formik.errors.vehicleNum&&
                      <div className={`mcAddVehicleBtn ${totalVehicle===1?"plus-show":"plus-hide"}`} onClick={()=>setTotalVehicle(totalVehicle+1)} >+ Add Vehicle</div>

                      } */}

                          {
                            totalVehicle>=2&&
                            <div>
                              <div className="vehRow">
                                <input
                                name="vehicleNum2"
                                maxLength="11"
                                 {...formik.getFieldProps('vehicleNum2')}
                                 placeholder="Vehicle Number"/>
                                {/* <img height='100%' src={require('../assets/images/mcPageImg/car-gray-mini.svg').default} alt="" /> */}
                              </div>
                              {formik.values.vehicleNum2&&formik.values.vehicleNum2.length>=5&&
                                    <div className="field-err-text">{formik.errors.vehicleNum2}</div>
                                  }

                          {/* {
                            formik.values.vehicleNum2&&!formik.errors.vehicleNum2&&
                            <div className={`mcAddVehicleBtn ${totalVehicle===2?"plus-show":"plus-hide"}`} onClick={()=>setTotalVehicle(totalVehicle+1)} >+ Add Vehicle</div>
                          } */}
                            </div>
                        }
                          {
                            totalVehicle===3&&
                            <div>
                              <div className="vehRow">
                                <input 
                                maxLength="11"
                                name="vehicleNum3"
                                {...formik.getFieldProps('vehicleNum3')}
                                placeholder="Vehicle Number"/>
                                {/* <img height='100%' src={require('../assets/images/mcPageImg/car-gray-mini.svg').default} alt="" /> */}
                              </div>
                              {formik.values.vehicleNum3&&formik.values.vehicleNum3.length>=5&&
                                    <div className="field-err-text">{formik.errors.vehicleNum3}</div>
                                  }
                            </div>
                        }
                              {
                                totalVehicle!==3&&
                                <div className="mob-add-vicle"><div className='mcAddVehicleBtn' onClick={(prev)=>setTotalVehicle(totalVehicle+1)} >+ Add Vehicle</div></div>
          
                              }


                    </div>

                {
                  loading&&
                
                  // <LinearProgress color='secondary' style={{marginTop:'8px',width:'200px'}} />
                  <Loader width='200px' />
                }
                </div>
                 
            {formik.dirty&&formik.isValid?
                <div className="reg-here-btn reg-btn"
                onClick={register} style={{marginTop:'8px'}}
                >NEXT</div>:
                <div className="reg-here-btn-disabled reg-btn" style={{marginTop:'8px'}}
                disabled
                >NEXT</div>
                }
              </div>
            </>
        }
      
        </div>
}
          </div>

    </div>
    </Wrapper>
      <FooterSmall />
      <FooterMotorClub/>
      </>
  );
}

export default MotorClub;

const Wrapper = styled.div`
  .content{
    display:flex;
    align-items: center;
    flex:1;
    padding-left: 10%;
  }
  .fNameContainer,.lNameContainer,.vehicleCons{
          input{
            text-transform: uppercase;
          }
        }
    .numberContainer{
      
    }
  @media only screen and (max-width: 600px) {
    .motorClubPage{
      display:flex;
      justify-content: center;
    }
      .content{
        flex-direction: column-reverse;
        justify-content: space-around;
        padding-left: 0;
        height: 86vh;
      }
      .reg-helper-text{
        text-align: center;
        max-width: 400px;
        width: 100% !important;
      }
      .login-header{
        margin-bottom: 2rem;
      }
      .numberContainer{
        display:flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
        
        .login-input{
          border: 1px solid lightgray;
          height: 30px;
          padding: 0rem;
          border-radius: 0px;
          text-align: center;
        }
      }
      .mob-login-next{
        width: 200px;
        display: flex;
        justify-content: center;
        margin-top: 3rem;
        button{
          padding: 2px 21px;
          border-radius: 0px;
        }
      }
      .mc-otp-input{
        margin-left: 0px;
        margin-right:0px;
      }
      .mc-input-tag{
        width:2rem;
      }
      .otpContainer{
        display:flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
      }
      .mob-add-vicle{
        display: flex;
        justify-content: center;
      }
      .mcregContainer{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
        .reg-btn{
          padding: 2px 21px;
          border-radius: 0px;
        }
        .mcReg-input-con{
          max-width: 400%;
          width: 100%;
          padding: 1.5rem 4rem;
          input{
            width:100%;
            height: auto;
            padding: 3px 0px;
            border-radius: 0px;
            text-align: center;
            font-size: 12px;
          }
          .mob-nameConainer{
            display: grid;
            grid-template-columns: 48.5% 48.5%;
          }
        }
        }

    }
`