
import axios from 'axios';

const API = axios.create({baseURL:'https://fgngapi.futuregroup.in'})

// const GET_MEM_DETAIL = "https://fgngapi.futuregroup.in/preprod-motorclub/api/v1/getCustMembershipData"

export const getCustDetails=(custMobileNo)=> 
            API.post('/motorclub/api/v2/getCustMembershipData',{
                sellerid:203,
                custMobileNo,
                membershipType:1,
                authenticated:1
            })


export const checkLeadInterest=(number,prodId)=> API.post('/api/v1/getLeadReference',
                                                            {
                                                                custmobileno:number,
                                                                platformprodid:prodId
                                                            }
                                                        )



export const captureLead=(number,fName,lName,dob,prodId)=> API.post('/api/v1/FGnGCreateLead',{
    sellerid:203,
    custMobileNo:number,
    custFirstName:fName,
    custLastName:lName,
    custDOB:dob,
    platformprodid:prodId,
    utmSource: JSON.parse(localStorage.getItem('utmSource')),
          utmMedium: JSON.parse(localStorage.getItem('utmMedium')),
          utmCampaign: JSON.parse(localStorage.getItem('utmCampaign')),
          utmTerm: JSON.parse(localStorage.getItem('utmTerm')),
          utmContent: JSON.parse(localStorage.getItem('utmContent'))
})