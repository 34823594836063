import React,{useEffect} from 'react';

import styled from 'styled-components';
import {Link,useHistory,useParams,useLocation} from 'react-router-dom';
import {useSelector,useDispatch} from 'react-redux';
import { getCust } from '../pages/actions/customerAction';
import { LOGOUT } from '../pages/constants';
import jwt from 'jsonwebtoken';
import {isMobile} from "react-device-detect";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import BrightnessAutoOutlinedIcon from '@mui/icons-material/BrightnessAutoOutlined';
// import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
// import MenuIcon from '@mui/icons-material/Menu';
const HeaderMotorClub = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const params = useParams();
    const location = useLocation();

    const query = new URLSearchParams(location.search).toString();
      
    const custDetail = useSelector(state=>state.offerReducer.customer.membershipdetails);

    const custData = JSON.parse(sessionStorage.getItem('cust'));

    const checkDevice=()=>{
        if(!custData){
            history.push(`/motor-club?${query}`)
        }
        if(params.number&&!isMobile){
            history.push('/');
            
        }
    }


    const handleLogout=(e)=>{
        e.preventDefault();
        sessionStorage.removeItem('cust');
        dispatch({type:LOGOUT})
        history.push(params.number?`/${params.number}`:'/')
    }


useEffect(()=>{

    checkDevice();
    if(!custDetail && custData){
        dispatch(getCust(custData.mobileNumber,"auth"));
    }else if(params.number&&!custData){
        try {
            let key ='ZindgiKeyStringZindgiKeyStringZindgiKeyString';
            const decoded = jwt.verify(params.number,key);
            dispatch(getCust(parseInt(decoded.mobile)));
        } catch (error) {
            console.log('error decoding data in mc')
        }
    }


},[])

    return (
        <>
        <Wrapper>

            {
                !isMobile&&
           <div className="desktop-menu">
            <Logo >
                <Link to='/motor-club/offers' >
                    <img src={require("../assets/images/mcPageImg/logo-white-new.png").default} alt="mcLogo" />
                </Link>
                <div className="navItems">
                    <Link to='/' >
                        <p  ><HomeOutlinedIcon style={{color:'white',fontSize:'32px',marginLeft:'auto'}} />Home</p>
                    </Link>
                   
                {
                    custData&&
                    <a href='/motor-club/offers#exclusiveOffers' >
                        <p className={location.pathname==='/motor-club/offers'?'current':''} >
                            <BrightnessAutoOutlinedIcon style={{color:'white',fontSize:'32px',marginLeft:'auto'}} />Offers
                        </p>
                    </a>
                }
                </div>
                
                   {/* location.pathname==='/motor-club'&&
                   <div className='navItems' >
                        <Link to='/' >
                            <p className={location.pathname==='/motor-club/offers'?'current':''} >Home</p>
                         </Link>
                     </div> */}
                
            
            </Logo>
           
            {
                custData&&
            <CusDetails className="dropdown" >
                
                <div className="detail"  data-toggle="dropdown"   >
                    <img src={require('../assets/images/mcPageImg/user-red.svg').default} alt="" />
                </div>
                
                <div className="dropdown-menu dropdown-menu-right" >
                    <div className="dropdownWrapper">
                        <div className="cust-image" >
                            <img src={require('../assets/images/mcPageImg/user-profile.png').default} alt="user profile" />
                        </div>
                        <Link to={params.number?`/${params.number}/motor-club/profile`:'/motor-club/profile'} className="profileBtn" >
                            <button className="profileBtn" >
                                PROFILE
                            </button>
                        </Link>
                        <div className="dropdown-divider"></div>
                        <button className="logoutBtn" onClick={handleLogout} >
                   	
                        <span><img style={{width:'15px'}} src={require('../assets/images/mcPageImg/logout-icon.svg').default} alt="" /></span> LOGOUT</button>
                    </div>
                </div>              
            </CusDetails>
            }
            </div>
            }
            {
                isMobile&&
                <div className="mobile-menu">
                    <Logo >
                        <Link to='/motor-club/offers' >
                            <img src={require("../assets/images/mcPageImg/logo-white-new.png").default} alt="mcLogo" />
                        </Link>
                    </Logo>
                    {/* <Link to='/' style={{marginLeft:'auto',marginRight:'30px'}} >
                        <HomeOutlinedIcon style={{color:'white'}} />
                    </Link> */}
                {
                custData?
                    <CusDetails className="dropdown" >
                        <div className="detail"  data-toggle="dropdown"   >
                            <img src={require('../assets/images/mcPageImg/user-red.svg').default} alt="" />
                        </div>              
                        <div className="dropdown-menu dropdown-menu-right" >
                            <div className="dropdownWrapper">
                                <div className="cust-image" >
                                    <img src={require('../assets/images/mcPageImg/user-profile.png').default} alt="user profile" />
                                </div>
                                <Link to={params.number?`/${params.number}/motor-club/profile`:'/motor-club/profile'} className="profileBtn" >
                                    <button className="profileBtn" >
                                        PROFILE
                                    </button>
                                </Link>
                                <div className="dropdown-divider"></div>
                                <button className="logoutBtn" onClick={handleLogout} >
                            
                                <span><img style={{width:'15px'}} src={require('../assets/images/mcPageImg/logout-icon.svg').default} alt="" /></span> LOGOUT</button>
                            </div>
                            </div>
                    </CusDetails>:
                    <Link to='/' >
                        <p  style={{color:'white'}} ><HomeOutlinedIcon style={{color:'white',fontSize:'28px',marginLeft:'auto'}} /></p>
                    </Link>
                }          
                </div>
            
            }
        </Wrapper>

            
        </>
    )
}

export default HeaderMotorClub

export const Wrapper = styled.div`
.desktop-menu{
    background-color: #EB2027;
    display:flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 23px;
    }
.mobile-menu{
    background-color: #EB2027;
    align-items: center;
    justify-content: space-between;
    padding: 19px 21px 9px 8px;
    border-radius: 0rem 0rem 2rem 2rem;
    display:none;
}
.flex-row-center{
    display: flex;
    justify-content: center;
}
@media only screen and (max-width: 600px) {
     
   .desktop-menu{
            border-radius: 0px 0px 27px 27px;
            width:100%;
   }
   .mobile-menu{
        display:flex;
        img{
            width: 100px;
            height: auto;
            padding:0px;
        }
        .cust-image{
              width: auto;
              height: auto;
              border-radius:50%;
              background:#fff;
            img{
                padding:0px;
            }
            .user-icon-1{
                   color: #EB2027;
            }
        }
        .sc-eCstlR .dLqprs{
            width: 123px;
            height: auto;
            padding: 0px;
        }
   }
}
`

const Logo = styled.div`
    height: 80px;
    padding-bottom: 6px;
    padding-top: 3px;
    padding:5px;
    display:flex;
    .navItems{
        display: flex;
        align-items: center;
        gap:0 30px;
        margin-left: 42%;
        a{
            text-decoration:none;
            color:inherit;
        }
        p{
            color:white;
            text-transform:uppercase; 
            font-size: 19px;
            padding: 31px 16px;
            margin-bottom: 0;
            white-space: nowrap;
            display: flex;
            align-items: center;
            gap:3px;
        }
        .current{
            background-color: rgb(242 99 103);
            color:white;
        }
    }
    @media only screen and (max-width: 600px) {
           height: 58px;
           padding-bottom: 5px;
           .navItems{
               display:none;
           }
           .desktop-menu{
               
           }
           .mobile-menu{
               display:block;
           }
        }
    img{
        height: auto;
        padding: 9px 0;
        padding-left: 14px;
        object-fit: contain;
        max-height: 100%;
    }
    .mobile-menu{
        display:none;
       
    }
`
const CusDetails = styled.div`
.detail{
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height:60px;
    width:60px;
    border-radius: 50%;
    img{
        height:100%;
    }
    @media only screen and (max-width: 600px) {
             height: 30px;
          width: 30px;
            }
}
.dropdownWrapper{
:before{
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    z-index: -1;
}
    display:flex;
    flex-direction: column;
    justify-content: center;
    .cust-image{
        margin:auto;
        padding:15px;
    }
    .profileBtn{
        border:none;
        background-color:#ec2127;
        color:white;
        width:100px;
        margin:auto;
        font-size: 19px;
    }
    .logoutBtn{
        border:none;
        background: none;
        color:#ec2127;
        font-size: 19px;
    }

    @media only screen and (max-width: 600px) {
        .logoutBtn{
            font-size: 12px;
        }
        .profileBtn{
            font-size: 12px;
        }
        .cust-image img{
            width:57px;
        }
         /* @media only screen and (max-width: 600px) {
                img{
            width: 119px;
            height: auto;
            padding: 0px;
            object-fit: contain;
            }
        } */
    }
}
`
