import React from 'react';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import BrightnessAutoOutlinedIcon from '@mui/icons-material/BrightnessAutoOutlined';
import CleanHandsOutlinedIcon from '@mui/icons-material/CleanHandsOutlined';

import {Link} from 'react-router-dom';
import {isMobile} from 'react-device-detect';
export default function FooterSmall() {
  
  
  const custData = JSON.parse(sessionStorage.getItem('cust'));

  return (
    <>
    <Wrapper>
      {
        !isMobile&&
    <div className="footer-con footer-con-small">
      <Grid container >
        <Grid item md={8}>
          <div className="footer-sm-title">FG&G Distribution Pvt. Ltd.</div>
          <div>(A JV between Future Group and Assicurazioni Generali)</div>
          <div>CIN: U66000MH2018PTC316154 | IRDA Registration number: CA0622</div>
          <div>Category: Corporate Agent (Composite)</div>
          <div className="termsAndPrivacy" >
            <a href='https://dd000pqh18y4j.cloudfront.net/HZ_Website/HZ_PDF/TnC/T%26CAgreement.pdf' target="_blank" rel="noreferrer"> <img alt=""  />Terms & Conditions</a>
            <a href='https://dd000pqh18y4j.cloudfront.net/HZ_Website/HZ_PDF/PrivacyPolicy/PrivacyPolicy.pdf' target="_blank" rel="noreferrer"> <img alt="" />Privacy Policy</a>
          </div>
        </Grid>
        <Grid item md={4} xs={12}>
          <div className="sm-connect-media">
            <div>Connect with us</div>
            <div className="media-links-sm">
              <a href="https://www.facebook.com/Hello-Zindagi-Insurance-109215517986276" target="_blank" rel="noreferrer"><img alt="" src={require('../assets/images/facebook.svg').default} /></a>
              <a href="https://twitter.com/hellozindgi1" target="_blank" rel="noreferrer" ><img alt="" src={require('../assets/images/twitter.svg').default} /></a>
              <a href="https://www.linkedin.com/company/77373988/admin/" target="_blank" rel="noreferrer"><img alt="" src={require('../assets/images/linkedin.svg').default} /></a>
              <a href="https://api.whatsapp.com/send/?phone=918767516040&text=hello" target="_blank" rel="noreferrer"><img alt="" src={require('../assets/images/whatsapp.svg').default} /></a>
            </div>
          </div>
        </Grid>

      </Grid>
    </div>
      }
    {
      custData&&isMobile?
    <div className="mob-fotter-menu" >
        <div className="flex-sb-row footer-menu-list">
           <div className="flex-center-col">
              <Link to="/" >
                <span className="menu-icon"> <HomeOutlinedIcon /></span>
                <span className="menu-text">Home</span>
              </Link>
           </div>
           <div className="flex-center-col">
             <a href='/motor-club/offers#exclusiveOffers' >
                <span className="menu-icon"> <BrightnessAutoOutlinedIcon /></span>
                <span className="menu-text">Offer</span>
             </a>
           </div>
           <div className="flex-center-col">
            <a href={require('../assets/pdf/PDF/tncApp.pdf').default} > <img alt=""  />
                   <span className="menu-icon">  
                      <CleanHandsOutlinedIcon/>
                    </span>
                    <span className="menu-text">T&C</span>
            </a>
           </div>
        </div>
    </div>:!custData&&isMobile&&
    <div className="footer-con footer-con-small">
    <Grid container >
      <Grid item md={8}>
        <div className="footer-sm-title">FG&G Distribution Pvt. Ltd.</div>
        <div>(A JV between Future Group and Assicurazioni Generali)</div>
        <div>CIN: U66000MH2018PTC316154 | IRDA Registration number: CA0622</div>
        <div>Category: Corporate Agent (Composite)</div>
        <div className="termsAndPrivacy" >
          <a href='https://dd000pqh18y4j.cloudfront.net/HZ_Website/HZ_PDF/TnC/T%26CAgreement.pdf' target="_blank" rel="noreferrer" download> <img alt=""  />Terms & Conditions</a>
          <a href='https://dd000pqh18y4j.cloudfront.net/HZ_Website/HZ_PDF/PrivacyPolicy/PrivacyPolicy.pdf' target="_blank" rel="noreferrer"> <img alt="" />Privacy Policy</a>
        </div>
      </Grid>
      <Grid item md={4} xs={12}>
        <div className="sm-connect-media">
          <div>Connect with us</div>
          <div className="media-links-sm">
            <a href="https://www.facebook.com/Hello-Zindagi-Insurance-109215517986276" target="_blank" rel="noreferrer"><img alt="" src={require('../assets/images/facebook.svg').default} /></a>
            <a href="https://twitter.com/hellozindgi1" target="_blank" rel="noreferrer" ><img alt="" src={require('../assets/images/twitter.svg').default} /></a>
            <a href="https://www.linkedin.com/company/77373988/admin/" target="_blank" rel="noreferrer"><img alt="" src={require('../assets/images/linkedin.svg').default} /></a>
            <a href="https://api.whatsapp.com/send/?phone=918767516040&text=hello" target="_blank" rel="noreferrer"><img alt="" src={require('../assets/images/whatsapp.svg').default} /></a>
          </div>
        </div>
      </Grid>
    </Grid>
  </div>

    }

    </Wrapper>
    </>
  );
}

const Wrapper = styled.div`

    
  .footer-menu-list{
    position: relative;
    height: 100%;
    padding: 0rem 4rem;
  }
  .menu-icon{
    color:lightgrey;
    text-align:center;
  }
  .menu-text{
    line-height:1;
    font-size:12px;
    color:lightgrey;
    text-align:center;
  }
  .flex-sb-row{
    display:flex;
    justify-content:center;
  }
  .flex-sb-row{
    display:flex;
    justify-content:space-between;
  }
  .flex-center-col{
    display:flex;
    justify-content:center;
    flex-direction:column;
  }
  .termsAndPrivacy{
    display:flex;
    flex-direction: column;
    margin-top: 15px;
    a{
      color:white;
      font-weight: 700;
      text-decoration:underline;
      font-size: 0.8rem;
    }
  }
  .mob-fotter-menu{
    /* display:none; */
    
    bottom: 0;
    height: 60px;
    color: red;
    left: 0;
    right:0;
    background-color: #EB2027;
    a{
      color:inherit;
    }
  }
  /* @media screen and (max-width: 400px), 
       screen and (min-height: 700px) {
         a{
           text-decoration:none;
         }
         .footer-con-small{
             display:none
         }
         .mob-fotter-menu{
           display:block;
         }

  } */
`