import axios from 'axios';


import {
  FETCH_ACTIVE_ASSETS
} from '../constants/index';

// const API = axios.create({baseURL:'https://fgngapi.futuregroup.in'});
//const API = ({'https://fgngapi.futuregroup.in'});

   axios.interceptors.request.use((req)=>{
 
  req.headers['Authorization'] = 'Basic ZmduZzpGZ25nQDEyMzRwcm9k'
  // req.headers['Access-Control-Allow-Origin']= '*'
  
return req;
});

// const preProd= 'https://fgngapi.futuregroup.in/preprod-asset/api/v1/FGnGGetActiveAssets';

const prod = 'https://fgngapi.futuregroup.in/asset/api/v1/FGnGGetActiveAssets';

const getAsset=(channelId,number)=> axios.post(prod,{
                                        channelId:channelId,
                                        sectionId:1,
                                        authenticated:1,
                                        mobileNumber:number
                                    })



export const getActiveAssets=(chId,no)=>async(dispatch)=>{
  
  try {
     const {data} = await getAsset(chId,no);
    
    dispatch({type:FETCH_ACTIVE_ASSETS,payload:data})
  } catch (error) {
    console.log("error fetching assets")
    
  }
}