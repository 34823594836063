import React,{useState,useEffect,useRef} from 'react';
import styled from 'styled-components';
import Carousel from 'react-material-ui-carousel';
import MultiCarousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import '../App.css';

import HeaderMotorClub from '../components/HeaderMotorClub';
import FooterSmall from '../components/FooterSmall';
import FooterMotorClub from './FooterMotorClub';
import {whyMotorClub} from './config/cards';
import { useDispatch,useSelector } from 'react-redux';
import { getActiveAssets } from './actions/offersAction';
import { useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';


const MotorClubOffers = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const storedCust = JSON.parse(sessionStorage.getItem('cust'));

        
    const [vehicle, setvehicle] = useState();
    const [category, setCategory] = useState([]);
    const [selectBrand, SetselectBrand] = useState([]);
    const [deals, setDeals] = useState('1');
    const [option, setOption] = useState(1);

    
    const banner= useSelector(state=>state.offerReducer.offers.filter(elem=>elem.assetType===1));
    const custDetails= useSelector(state=>state.offerReducer.customer.membershipdetails);
    const exclusiveOffers = useSelector(state=>state.offerReducer.offers.filter(elem=>{
        
        if(vehicle&&selectBrand&&category.length>0){
            return elem.assetType===2&&category.includes(elem.categoryId)&&selectBrand.includes(elem.partner)&&elem.tags.includes(vehicle);
        }
        if(vehicle&&category.length>0){
            return elem.assetType===2&&elem.tags.includes(vehicle)&&category.includes(elem.categoryId)&&elem.tags.includes('4');
        }
        if(vehicle&&selectBrand.length>0){
            return elem.assetType===2&&elem.tags.includes(vehicle)&&selectBrand.includes(elem.partner)&&elem.tags.includes('4');
        }
        if(category.length>0&&selectBrand.length>0){
            return elem.assetType===2&&category.includes(elem.categoryId)&&selectBrand.includes(elem.partner)&&elem.tags.includes('4');
        }
        if(vehicle){
            return elem.assetType===2&&elem.tags.includes(vehicle)&&elem.tags.includes('4');
        }
        if(selectBrand.length>0){
            return elem.assetType===2&&selectBrand.includes(elem.partner)&&elem.tags.includes('4');
        }
        if(category.length>0){
            return elem.assetType===2&&category.includes(elem.categoryId)&&elem.tags.includes('4');
        }
        
        else return elem.assetType===2&&elem.tags.includes('4')
    }))

    

    const amazingOffers = useSelector(state=>state.offerReducer.offers.filter(elem=>{
    
        // return elem;

        if(deals){
           return elem.assetType===2&&elem.tags.includes(deals);
        }
        return elem.assetType===2&&(elem.tags.includes("1")||elem.tags.includes("2")||elem.tags.includes("3") )

    }))


    if(custDetails){
        let data = {
            mobileNumber:custDetails.custmobileno,
            membershipId:custDetails.membershipid,
            firstName:custDetails.custfirstname,
            lastName:custDetails.custlastname
        }
        sessionStorage.setItem("cust", JSON.stringify(data));
    }
    
    
// const filterVehicleType=(id)=>{
//     setvehicle(id);
// }

const filterCategory=(cat)=>{
    if(category.includes(cat)){
        let removeCat= category.filter(elem=>elem!==cat);
        setCategory(removeCat);
    } else{
        setCategory([...category,cat]);
    }
}

const filterBrand=(brand)=>{
    if(selectBrand.includes(brand)){
        let removeBrand= selectBrand.filter(elem=>elem!==brand);
        SetselectBrand(removeBrand);
    }else{
        SetselectBrand([...selectBrand,brand]);
    }
}

const fetchOffers=()=>{
    if(custDetails||storedCust){
        dispatch(getActiveAssets("2",custDetails&&custDetails.custmobileno?custDetails.custmobileno:storedCust.mobileNumber));
    }
}


  const ref = useRef(null);

  const scroll=(scrollOffset)=>{
      ref.current.scrollLeft += scrollOffset;
  }

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3
    }
}

  useEffect(()=>{

    fetchOffers();
   // dispatch({type:FETCH_OFFERS,payload:assets})
    
  },[])

    return (
        <>
        <HeaderMotorClub/>
        <Wrapper>
            {/* <HeroSlider>
                <img src={require('../assets/images/mcPageImg/banner.png').default} alt="" />
            </HeroSlider> */}
            <div className="carousel">
                <Carousel 
                autoPlay={false}
                animation="fade"
                arrows={false}
                indicatorIconButtonProps={{
                    style: {   
                        padding:'12px',
                        color: 'white' ,
                        display:'none'
                        
                    }
                }}
                navButtonsAlwaysVisible={false}
                navButtonsProps={{
                    className:"bannerNavBtn",
                    
                }}
                
                >                         
                     {banner&&banner.map((elem,index)=>(
                        
                        <img key={index} src={`https://dd000pqh18y4j.cloudfront.net/HZ_Website/motorClub/Assets/${elem.fileName1}`} alt="" />
                    ))}   
                        {/* <img src={require('../assets/images/mcPageImg/banner.png').default} alt="" />
                        <img src={require('../assets/images/mcPageImg/banner.png').default} alt="" /> 
                    
                         <img src={require('../assets/images/mcPageImg/banner.png').default} alt="" /> */}
                        {/* <img src={require('../assets/images/mcPageImg/banner.png').default} alt="" />  */}

                </Carousel>

            </div>
            {/* <div style={{display:'flex'}} >
            {
                filOffer.map(elem=>{
                    return( 
                    <>
                        <div style={{border:'2px solid black',width:'150px',height:'150px',margin:'8px'}} >
                            <p>vehicle:{elem.vehicle}</p>
                            <p>category:{elem.category}</p>
                            <p>brand{elem.brand}</p>
                        </div>
                    </>
                    )
                })
            }

            </div> */}
            
            <ExclusiveOffers>
                <Options>
                    {/* <VehicleSelect>
                        <p className='title'>VEHICLE</p>
                        <div className="vehicle">
                            {vehicle===5?
                            <img src={require('../assets/images/mcPageImg/scooter-red-mini.png').default} alt="mini red scooter" />:
                            <img src={require('../assets/images/mcPageImg/scooter-gray-mini.png').default} alt="mini gray scooter" 
                            onClick={()=>filterVehicleType(5)} />
                            }
                            {
                            vehicle===6?
                            <img src={require('../assets/images/mcPageImg/car-red-mini.png').default} alt="mini red car" />:
                            <img src={require('../assets/images/mcPageImg/car-gray-mini.png').default} alt="mini gray car"
                            onClick={()=>filterVehicleType(6)}
                            />
                            }
                        </div>
                    </VehicleSelect> */}


                    <div className="optionContainer">
                        <p className={`option ${option===1?'selectedOption':''}`} onClick={()=>setOption(1)} >CATEGORY</p>
                        <p className={`option ${option===2?'selectedOption':''}`} onClick={()=>setOption(2)}>BRANDS</p>
                    </div>
                    <CategorySelect>
                        <p className={`title`} >CATEGORY</p>
                        <div className={`category ${isMobile&&option===1?'mobileCategory':''}`} >
                    
                            <button className={category.includes(1)?"categoryBtn selected":"categoryBtn"} onClick={()=>filterCategory(1)} >
                                Vehicle Inspection Services
                            </button>
                            <button className={category.includes(2)?"categoryBtn selected":"categoryBtn"} onClick={()=>filterCategory(2)}>
                                Vehicle Spare Parts
                            </button>
                            <button className={category.includes(4)?"categoryBtn selected":"categoryBtn"} onClick={()=>filterCategory(4)}>
                                Car Insurance
                            </button>
                            <button className={category.includes(5)?"categoryBtn selected":"categoryBtn"} onClick={()=>filterCategory(5)}>
                                Fastag
                            </button>
                            <button className={category.includes(6)?"categoryBtn selected":"categoryBtn"} onClick={()=>filterCategory(6)}>
                                Car Detailing
                            </button>
                            <button className={category.includes(8)?"categoryBtn selected":"categoryBtn"} onClick={()=>filterCategory(8)}>
                                Car AC Services
                            </button>
                            <button className={category.includes(9)?"categoryBtn selected":"categoryBtn"} onClick={()=>filterCategory(9)}>
                                Bike Insurance
                            </button>
                            <button className={category.includes(10)?"categoryBtn selected":"categoryBtn"} onClick={()=>filterCategory(10)}>
                                Vehicle Accessories
                            </button>
                            <button className={category.includes(11)?"categoryBtn selected":"categoryBtn"} onClick={()=>filterCategory(11)}>
                                Vehicle Finance
                            </button>
                            <button className={category.includes(12)?"categoryBtn selected":"categoryBtn"} onClick={()=>filterCategory(12)}>
                                Test Ride	
                            </button>
                            <button className={category.includes(13)?"categoryBtn selected":"categoryBtn"} onClick={()=>filterCategory(13)}>
                                Tyres Services
                            </button>
                            <button className={category.includes(15)?"categoryBtn selected":"categoryBtn"} onClick={()=>filterCategory(15)}>
                                Vehicle Annual Maintainance	
                            </button>
                            <button className={category.includes(16)?"categoryBtn selected":"categoryBtn"} onClick={()=>filterCategory(16)}>
                                Car Battery Doorstep
                            </button>
                            <button className={category.includes(17)?"categoryBtn selected":"categoryBtn"} onClick={()=>filterCategory(17)}>
                                Car Battery Replacement
                            </button>
                            <button className={category.includes(3)?"categoryBtn selected":"categoryBtn"} onClick={()=>filterCategory(3)}>
                                Vehicle Battery Services
                            </button>
                            <button className={category.includes(14)?"categoryBtn selected":"categoryBtn"} onClick={()=>filterCategory(14)}>
                                Oil Lubricants& Other Consumables
                            </button>
                            <button className={category.includes(7)?"categoryBtn selected":"categoryBtn"} onClick={()=>filterCategory(7)}>
                                Car Repairs& Routine Service
                            </button>
                        
                        </div>
                    </CategorySelect>
                    <BrandSelect>

                    <p className={`title`}>BRANDS</p>
                    <div className={`brands ${option===2?'mobileBrands':''}`} >
                        <button className={selectBrand.includes(1)?"brandsBtn selected":"brandsBtn"}
                        onClick={()=>filterBrand(1)}
                        >
                            CEAT
                        </button>
                        <button className={selectBrand.includes(2)?"brandsBtn selected":"brandsBtn"}
                            onClick={()=>filterBrand(2)}>
                                Pitsop
                        </button>
                        
                        <button className={selectBrand.includes(3)?"brandsBtn selected":"brandsBtn"}
                        onClick={()=>filterBrand(3)}>SCAD</button>

                        <button className={selectBrand.includes(4)?"brandsBtn selected":"brandsBtn"}
                            onClick={()=>filterBrand(4)}>
                                IDFC First Bank
                        </button>
                        <button className={selectBrand.includes(5)?"brandsBtn selected":"brandsBtn"}
                            onClick={()=>filterBrand(5)}>
                            OTO Capital
                        </button>
                        {/* <button className={selectBrand.includes(6)?"brandsBtn selected":"brandsBtn"}
                            onClick={()=>filterBrand(6)}>
                                Battery Wale
                        </button> */}
                        <button className={selectBrand.includes(7)?"brandsBtn selected":"brandsBtn"}
                        onClick={()=>filterBrand(7)}>
                            Future Generali</button>
                        <button className={selectBrand.includes(8)?"brandsBtn selected":"brandsBtn"}
                        onClick={()=>filterBrand(8)}>boodmo
                        </button>
                        <button className={selectBrand.includes(9)?"brandsBtn selected":"brandsBtn"}
                            onClick={()=>filterBrand(9)}>GoMechanic
                        </button>
                        <button className={selectBrand.includes(10)?"brandsBtn selected":"brandsBtn"}
                            onClick={()=>filterBrand(10)}>Batterywala
                        </button>
                    </div>
                    </BrandSelect>
                </Options>
                <Cards leftArr={require('../assets/images/mcPageImg/arrow-left3.png').default}
                        rightArr={require('../assets/images/mcPageImg/arrow-left3.png').default}
                 >
                     <span className="leftArrow" onClick={() => scroll(-300)} > <img src={require('../assets/images/mcPageImg/arrow-left3.png').default} alt="" />‏‏‎ ‎</span>
                     <span className="rightArrow" onClick={() => scroll(300)}> <img src={require('../assets/images/mcPageImg/arrow-left3.png').default} alt="" /> ‏‏‎‎‎‏‏‎</span>
                    <h4 className='cardHeading' id='exclusiveOffers' ><span>EXCLUSIVE OFFERS FOR MEMBERS</span></h4>
                    
                    <div className="cardWrapper" ref={ref} >
                        {

                exclusiveOffers.length>0?exclusiveOffers.map((elem,index)=>(
                                <div className="cardContainer" key={index}>
                                    <a href={`https://dd000pqh18y4j.cloudfront.net/HZ_Website/motorClub/Assets/${elem.fileName2}`}>
                                        <img src={`https://dd000pqh18y4j.cloudfront.net/HZ_Website/motorClub/Assets/${elem.fileName1}`} alt="" />
                                    </a>
                                </div>
                            )):
                            <h3 style={{textAlign:'center'}} >No Offer Matched</h3>
                        }
                       
                        
                    </div>
                    <div className="bottomScrollBtn">
                        {
                            exclusiveOffers&&exclusiveOffers.length>0&&
                        <span >
                            <button className="scrollLeft" onClick={() => scroll(-300)} ><img src={require('../assets/images/mcPageImg/arrow-left2.svg').default} alt="" /> </button>
                            <button className="scrollRight" onClick={() => scroll(300)} ><img src={require('../assets/images/mcPageImg/arrow-right2.svg').default} /> </button>

                        </span>
                        }
                    </div>

            
                </Cards>
            </ExclusiveOffers>
            <AmazingOffers>
                <p className="title">AMAZING OFFERS AVAILABLE ON MOTOR CLUB</p>
                <Header>
                    {/* <div className="header-title">
                        Trending
                    </div> */}
                    <div className={deals==='1'?"header-title selected":"header-title"} onClick={()=>setDeals('1')} >
                        Hot Deals
                    </div>
                    <div className={deals==='2'?"header-title selected":"header-title"} onClick={()=>setDeals('2')} >
                        Limited Time Deals
                    </div>
                    <div className={deals==='3'?"header-title selected":"header-title"} onClick={()=>setDeals('3')} >
                        Coming Soon
                    </div>
                </Header>
        {(amazingOffers.length>0&&deals!=='3')&&
        
                    <MultiCarousel
                    responsive={responsive}
                    containerClass="amazingCarousel"
                    arrows={true}
                    >

                        {
                            amazingOffers.map((elem)=>(
                                 <div key={elem.assetId} className="cards" >
                                     <a href={`https://dd000pqh18y4j.cloudfront.net/HZ_Website/motorClub/Assets/${elem.fileName2}`}>
                                         <img src={`https://dd000pqh18y4j.cloudfront.net/HZ_Website/motorClub/Assets/${elem.fileName1}`} alt="" />
                                     </a>
                                 </div>
                                 ))

                        }                     
                     
                    </MultiCarousel>
        }
        {(deals==='3')&&
        
                    <MultiCarousel
                    responsive={responsive}
                    containerClass="amazingCarousel"
                    arrows={true}
                    >

                                           
                                <div  className="cards" >
                                   
                                        <img src={require('../assets/images/mcPageImg/coming-soon.jpeg').default} alt="" />
                                   
                                </div>
                                <div  className="cards" >
                                   
                                        <img src={require('../assets/images/mcPageImg/coming-soon.jpeg').default} alt="" />
                                   
                                </div>
                                <div  className="cards" >
                                   
                                        <img src={require('../assets/images/mcPageImg/coming-soon.jpeg').default} alt="" />
                                   
                                </div>
                     
                    </MultiCarousel>
        }
                            
            </AmazingOffers>
            <Brands>
                <p className="title">Motor Club Partners</p>
                <div className="brandWrapper">
                   
                        <div className="brands">
                            <img src={require('../assets/images/mcPageImg/brand-3.png').default} alt="" />
                        </div>
                        <div className="brands brand2">
                            <img src={require('../assets/images/mcPageImg/brand-7.png').default} alt="" />
                        </div>
                        <div className="brands">
                            <img src={require('../assets/images/mcPageImg/brand-5.png').default} alt="" />
                        </div>
                        <div className="brands">
                            <img src={require('../assets/images/mcPageImg/brand-2.png').default} alt="" />
                        </div>
                        <div className="brands">
                            <img src={require('../assets/images/mcPageImg/brand-1.png').default} alt="" />
                        </div>
                        <div className="brands">
                            <img src={require('../assets/images/mcPageImg/brand-6.png').default} alt="" />
                        </div>
                        <div className="brands">
                            <img src={require('../assets/images/mcPageImg/brand-4.png').default} alt="" />
                        </div>
                   
                </div>
            </Brands>
            <WhyMotorclub>
                <p className="title"><span>Why Motor Club?</span> </p>
                <div className="whyCardWrapper">
     
                            {/* <div className="whyCard">
                                <img src={require('../assets/images/mcPageImg/whymc-1.svg').default} alt="" />
                                <p style={{paddingTop:'26px'}} >India's only membership program for Vehicle lovers</p>
                            </div> */}
                    {
                        whyMotorClub.map((elem,index)=>(
                            <div key={index} className="whyCard">
                                <img className="whymcImg" src={elem.image} alt="" />
                                <p>{elem.text}</p>
                            </div>
                        ))
                    }
                </div>
            </WhyMotorclub>
            <Benefits>
                <p className="title">Miles of benefits</p>
                <div className="benefitCardWrapper">
                    <div className="benefitCard">
                        <p className="benefitCount">45000+</p>
                        {/* <p className="plus">+</p> */}
                        <p className="text">4-Wheelers Owners</p>
                    </div>
                    <div className="benefitCard">
                        <p className="benefitCount">55000+</p>
                        {/* <p className="plus">+</p> */}
                        <p className="text">2-Wheelers Owners</p>
                    </div>
                    <div className="benefitCard">
                        <p className="benefitCount">350+</p>
                        {/* <p className="plus">+</p> */}
                        <p className="text">Available Cities</p>
                    </div>
                    <div className="benefitCard">
                        <p className="benefitCount">20+</p>
                        {/* <p className="plus">+</p> */}
                        <p className="text">Partners</p>
                    </div>
                </div>
            </Benefits>
            
        </Wrapper>
        <FooterSmall/>
        <FooterMotorClub/>
        </>
    )
}

export default MotorClubOffers

const Wrapper = styled.div`

/* .multiCarousel{
    color:red;
    .react-multi-carousel-track{
        overflow: auto;
    }
    .react-multiple-carousel__arrow, .react-multiple-carousel__arrow--right{
        background-color: transparent;
        color:black;
        :before{
            color:black;
        }
    }
} */
.carousel{
   
    img{
    /* max-height: 400px; */
    height:100%;
    width: 100vw;
    object-fit: cover;
    margin-top: -5%;
    margin-bottom: -5%;
    min-height: 165px;
    z-index:-20;

    @media only screen and (max-width: 600px) {
            margin-top: 0;
            margin-bottom: 0;
    }

    }
    
    .bannerNavBtn{
        .MuiSvgIcon-root{
            font-size: 3em;
            width:1em;
            height: 1em;
            display:none;
        }
        background:transparent;
        color:white;
        
        .MuiSvgIcon-root{
            font-size: 3em;
            width:1em;
            height: 1em;
            display:none;
        }
        
        @media only screen and (max-width: 600px) {
            .MuiSvgIcon-root{
                font-size: 1em;
                width:0.5em;
                height: 0.5em;
                display:none;
            }
            
        }
    }
}
`
const HeroSlider = styled.div`
height:400px;
img{
    
    height: 100%;
    width: 100%;
    object-fit: cover;
}

`
const ExclusiveOffers = styled.div`

    margin:5px 0;
    
    padding: 40px 45px;
    display:flex;
    margin: 50px 0 70px;
    @media only screen and (max-width: 600px) {
    flex-direction: column;
    padding: 3px 11px;
    padding-top: 18px;
    margin:0;
    }
    @media only screen and (max-width: 600px) {
        padding:15px 0;
    }
`
const Options = styled.div`
/* flex:0.4; */
width:32%;
padding:00px 30px;
/* height:300px; */



.title{
    color:#eb2027;
    font-size: 27px;
    font-weight: 700;
}
.optionContainer{
    display: none;
}
@media only screen and (max-width: 600px) {
        width:100%;
        padding:10px 16px;
        display: block;
        
        .optionContainer{
            display: flex;
            justify-content: center;
            .option{
                font-size: 9px;
                text-align: center;
                display: inline-block;
                font-weight: 400;
                border-radius: 6px;
                padding: 1px 6px;
                font-weight: 500;
            }
            .selectedOption{
            background-color: #ce001b;
            color:white;
        }
        }
    }
`
const VehicleSelect = styled.div`
    border-bottom: 1px solid #cbc4c466;
    padding-bottom: 33px;
    margin-bottom: 15px;
.vehicle{
    display:flex;
    gap:8px;
    align-items: center;
    img{
    height: 30px;
    box-shadow: 0px 0px 3px 0px #7571716e;
    border-radius: 10px;
    padding: 5px;
    }
}
@media only screen and (max-width: 600px) {
        width:100%;
        padding:10px 16px;
        display: flex;
        .title{
            font-size: 18px;
        }
    }
`
const CategorySelect = styled.div`
        border-bottom: 1px solid #cbc4c466;
        padding-bottom: 33px;
        margin-bottom: 22px;
        
.categoryBtn{
    border: none;
    border-radius: 8px;
    padding: 1px 18px;
    margin: 5px;
    color:#59595b;
    font-size: 21px;
    font-weight: 500;
    background-color: #f5f5f7;
}
.selected{
    color: #eb2027;
    font-weight: 500;
    background-color:#f9c7c8;
}
.category{
    overflow: auto;
    height: 333px;
}

@media only screen and (max-width: 600px) {
    border-bottom:none;
    padding-bottom:0; 
    margin-bottom: 0;
        .title{
            /* font-size: 8px;
            text-align:center;
            display: inline-block;
            font-weight: 400;
            border-radius: 8px;
            padding: 1px 6px; */
            display: none;
        }
        .categoryBtn{
            font-size: 10px;
            padding: 2px 5px;
            border-radius: 6px;
            font-weight: normal;
            white-space: nowrap;
        }
        .category{
            display: none;
            height:auto;
        }
        .selectedOption{
            background-color: #ce001b;
            color:white;
        }
        .option{
            display: flex-inline-flex;
        }
        
        .mobileCategory{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
    }

`
const BrandSelect = styled.div`
border-bottom:none;
.brands{
    
    height: 300px;
    overflow: auto;
}
.brandsBtn{
    border: none;
    border-radius: 8px;
    padding: 1px 18px;
    margin: 5px;
    color:#59595b;
    font-size: 21px;
    font-weight: 500;
    background-color: #f5f5f7;
}
.selected{
    color: #eb2027;
    font-weight: 500;
    background-color:#f9c7c8;
}
@media only screen and (max-width: 600px) {
    
    height:min-content;
        .brands{
            display: none;
            height:auto;
            overflow-y: initial;
        }
        .title{
            /* font-size: 8px;
            text-align:center;
            display: inline-block;
            font-weight: normal;
            border-radius: 8px;
            padding: 1px 6px; */
            display: none;
        }
        .selectedOption{
            background-color: #ce001b;
            color:white;
            border-radius: 8px;
            padding: 1px 6px;
        }
        .brandsBtn{
            font-size: 10px;
            padding: 2px 5px;
            border-radius: 6px;
            font-weight: normal;
            white-space: nowrap;
            
        }
        .mobileBrands{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
    }
`
const Cards = styled.div`
/* flex:0.6; */
/* max-width:63%; */
max-width: 904px;
border:2px solid #44404066;
padding:33px 36px;
position: relative;
margin:auto;
min-height: 428px;
.leftArrow{
    position: absolute;
    left: -31px;
    top: 48%;
    width:35px;
    img{
        width:100%;
    }
}
.rightArrow{
    position: absolute;
    right: -31px;
    top: 48%;
    width:35px;
    img{
        width:100%;
        transform: rotate(180deg);
    }
}
/* ::before{
    content:${props=>`url(${props.leftArr})`};
    position: absolute;
    display: block;
    top: 45%;
    left: -31px;
    transform: scale(0.7);
} */
/* ::after{
    content:${props=>`url(${props.rightArr})`};
    position: absolute;
    display: block;
    top: 45%;
    right: -31px;
    transform: scale(0.7) rotate(180deg);
    
} */

.cardHeading{
    color:#eb2027;
    text-align:center;
    padding-bottom: 15px;
    margin-top: -66px;
    z-index: 2;
    font-size: 32px;
    font-weight: 700;
    span{
        background: white;
        padding: 0 20px;
    }
}
.cardWrapper{
    display: grid;
    grid-template-rows: repeat(2,1fr);
    overflow: auto;
    grid-auto-flow: column;
    width: 100%;
    /* overflow-y: hidden;
    overflow-x: hidden; */
    scroll-behavior: smooth;
    gap:18px;
    border-radius:8px ;
    @media only screen and (max-width: 600px) {
        gap: 15px;
        display: flex;
        margin: 28px 18px;
        
        
    }
}
.cardContainer{
    height:260px;
    width:260px;
    box-shadow: 8px 3px 9px 0px #0000002e;;
    border-radius: 14px;
    
    img{
        height:100%;
        width:100%;
        border-radius: 14px;
    }
    @media only screen and (max-width: 600px) {
        width: auto;
        height: 130px;
        
    }
}
@media only screen and (max-width: 600px) {
        min-height:auto;
        
    }
.bottomScrollBtn{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: -79px;
    margin-top: 31px;
    gap:7px;
    .btnContainer{
        background:white;
    }
    button{
        border: none;
        margin: 0 10px;
        font-size: 21px;
        color: white;
        background-color: #8080809c;
        padding: 7px 10px;
        font-weight: 600;
        padding-bottom: 10px;
    }
    .scrollLeft{
        :hover{
            background-color:rgb(214 37 45);
        }
    }
    .scrollRight{
        :hover{
            background-color:rgb(214 37 45) ;

        }
    }
    span{
        background:white;
        padding:0 16px;
    }
    
}
@media only screen and (max-width: 600px) {
        max-width: 100%;
        overflow: initial;
        padding:10px 0px;
        margin-top:0px;
        border:none;
        :before,:after{
            display: none;
        }
        .cardHeading {
            font-size: 13px;
            margin-top: 0;
            color: black;
            font-weight: 500;
            text-align: left;
            padding-bottom: 0;
        }
        .cardWrapper{
            margin:20px 12px;
            gap: 10px;
            width:auto;
            height:141px;
           
        }
        .bottomScrollBtn{
            display: none;
        }
        .cardContainer{
            margin-right: 5px;
            img{
                width: 150px;
                object-fit: cover;
            }
        }
        .leftArrow,.rightArrow{
            display:none;
        }
    }
`
const AmazingOffers = styled.div`
    box-shadow: -1px 1px 10px 4px #5855554d;
    padding: 40px 45px;
.cards{
    background-color: gainsboro;
    margin-right: 1px;
    height:402px;
    border-radius: 0px;
    border:none;
}
.title{
    color:#eb2027;
    text-align:center;
    font-size: 34px;
    font-weight: 600;
    margin-bottom: 27px;
}
img{
    width: 100%;
    max-height: 400px;
    object-fit: cover;
    height:100%;
    border-radius: 0px;
}
.amazingCarousel{
    width: 100%;
}
@media only screen and (max-width: 600px) {
    padding: 3px 10px;
    padding-top: 20px;
    margin: 0 1px;
    .title{
        font-size: 12px;
        text-align:left;
        color:black;
        margin-bottom: 9px;
    }
    .cards{
        height:113px;
    }
    }

`
const Header = styled.div`
    display: grid;
    grid-template-columns: 33.3% 33.3% 33.3%;
    .header-title{
        padding: 13px 50px;
        background: rgb(193 193 193);
        color: white;
        font-weight: 400;
        margin: 1px 1px;
        text-align: center;
        cursor: pointer;  
        font-size: 20px;   
        }
    .selected{
        background-color:rgb(214 37 45);
    }
    @media only screen and (max-width: 600px) {
        .header-title{
            font-size: 11px;
            padding: 3px 9px;
            margin: 1px 1px;
            white-space: nowrap;
        }
    }
`
const AmazingCards = styled.div`
    display: grid;
    grid-template-columns: 33.3% 33.3% 33.3%;

`
const Brands = styled.div`
display:flex;
align-items: center;
margin:52px 5px;
margin-bottom: 45px;
padding:0 85px;
/* height:100px; */
    
.title{
    color:#eb2027;
    font-size: 21px;
    font-weight: 500;
}
.brandWrapper{
    display:flex;
    align-items: center;
    justify-content: space-around;
    padding: 20px 45px;
    /* height:100px; */
    flex:1;
}
.brands{
    border-right: 2px solid #eb202780;
    height: 100px;
    padding: 5px 10px;
    text-align: center;
    img{
        height: auto;
        width: 70%;
        padding-top: 27px;
    }
}
.brand2{
    height:100px;
    padding:5px 25px;
    img{
        width:54px;
        padding-top: 16px;
    }
}
@media only screen and (max-width: 600px) {
    height:auto;
    padding:0 7px;
    margin: 25px 0px;
    margin-bottom: 0px;
    align-items: baseline;
        .title{
            font-size:8px;
            white-space: nowrap;
        }
            .brandWrapper{
                flex-wrap: nowrap;
                padding: 0px 6px;
                gap:0px;
                .brands{
                    border-right:1px solid #eb202780;
                    width: 39px;
                    height: 34px;
                    padding:0;
                    img{
                        padding-top: 0;
                    }
                }
                .brand2{
                    height:34px;
                    width:29px;
                    padding-right:4px;
                    img{
                        width: 92%;
                        padding-top: 0;
                    }
                }
            }
        }
`
const WhyMotorclub = styled.div`
padding:20px 100px;

    .title{
        font-size: 32px;
        color:#eb2027;
        font-weight: 700;
        text-align:center;
        position: relative;
            :before{
                content: '';
                background: #eb2027;
                position: absolute;
                top: 70%;
                left: 0;
                height: 2px;
                z-index: -1;
                width: 40%;
            }
            :after{
                content: '';
                background: #eb2027;
                position: absolute;
                top: 70%;
                right: 0;
                height: 2px;
                z-index: -1;
                width: 40%;
            }
            span{
                background:white;
                padding:0 7px;
            }
    }
.whyCardWrapper{
    display:flex;
    align-items: baseline;
    justify-content: space-around;
    margin-top: 40px;
    .whyCard{
        width:250px;
        padding:15px;
        img{
            width:100%;
            height:110px;
            object-fit: contain;
        }
        p{
            padding-top: 20px;
            text-align:center;
            font-weight: 600;
            padding-top: 20px;
            text-align: center;
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
        }

    }
    
}
@media only screen and (max-width: 600px) {
    padding: 12px 13px;
    .title{
        font-size: 13px;
        :before,:after{
            height:1px;
        }
    }
    .whyCardWrapper{
        margin-top:0px;
        justify-content: center;
        gap:16px;
        .whyCard{
            padding:4px;
            p{
                font-size: 6px;
                font-weight: normal;
                line-height: normal;
                padding-top: 5px;
                margin-bottom: 0;
            }
            img{
                height:32px;
                width: 82%;
            }
        }
    }
        }
`
const Benefits = styled.div`
    background-color: #ce001b14;
    padding: 44px 0;
.title{
    text-align: center;
    color: #eb2027;
    font-size: 32px;
    font-weight: 700;
    padding-bottom: 10px;
}

.benefitCardWrapper{
    display:flex;
    align-items: flex-start;
    justify-content: space-evenly;
    text-align:center;
    
    .benefitCard{
        width:236px;
    }

    .benefitCount{
        font-size: 33px;
        font-weight: 600;
        padding-bottom: 3px;
       
    }
    .plus{
        font-size: 32px;
        margin-top: -40px;
    }
    .text{
        font-size: 20px;
        margin-top: -23px;
    }
}
@media only screen and (max-width: 600px) {
    padding: 8px 0;
    padding-bottom: 2px;
            .title{
                font-size: 13px;
                padding-bottom: 10px;
                margin-bottom: 0;
            }
            .benefitCardWrapper{
                justify-content: center;
                
                .benefitCard{
                    width:80px;
                    .benefitCount{
                        font-size: 10px;
                        font-weight: 400;
                    }
                    .plus{
                        font-size: 13px;
                        margin-top: -22px;
                    }
                    .text{
                        font-size: 7px;
                        margin-top: -20px;
                    }
                }
            }
        }
`