import {
  OFFERS,
  FETCH_CUSTOMER_DETAILS,
  FETCH_OFFERS,
  LOGOUT,
  REGISTER_MC,
  UPDATE_MC_DETAIL,
  FETCH_ACTIVE_ASSETS
} from '../constants/index';

const INITIAL_STATE = {
  offers: [],

  responseMsg: '',

  filteredOffers:[],

  customer: {},

  errorMsgExceedLimint: '',

  modal: false,
  isLoading: false,
};
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = INITIAL_STATE, action) {
  
  switch (action.type) {
    case OFFERS:
      return {
        ...state,
        offers: action.payload,
        responseMsg: action.payload.responseMessage,
        modal: false,
      };

    case FETCH_ACTIVE_ASSETS:
      return {
        ...state,
        offers:action.payload
      }


    case FETCH_CUSTOMER_DETAILS:
      let custData = {
        firstName:action.payload.membershipdetails.custfirstname,
        lastName:action.payload.membershipdetails.custlastname,
        membershipId:action.payload.membershipdetails.membershipid,
        mobileNumber:action.payload.membershipdetails.custmobileno
      }
      sessionStorage.setItem('cust',JSON.stringify(custData));
            
      return { ...state, customer: action.payload };

    
    case REGISTER_MC:
      let custmc = {
        firstName:action.payload.membershipDetails.custfirstname,
        lastName:action.payload.membershipDetails.custlastname,
        membershipId:action.payload.membershipDetails.membershipid,
        mobileNumber:action.payload.membershipDetails.custmobileno
      }
      sessionStorage.setItem('cust',JSON.stringify(custmc));
      return { ...state, customer: action.payload }

    case UPDATE_MC_DETAIL:
      return {...state,
        customer:{...state.customer,membershipdetails:action.payload} }

    case LOGOUT:
      return {...state,customer:{}}
   
      case FETCH_OFFERS:
        return{
          ...state,
          offers:action.payload
        }
      

    default:
      return state;
  }
}
